body {
    overflow-x: hidden;
}

.content-header {
    min-height: 200px;
    padding-top: 35px;
}

.widgets-holder {
    margin-top: 40px;
}

.widget-1-content {
    /*color: red;*/
    margin-bottom: 10px;
}

.slider {
    margin-top: -20px;
    margin-bottom: -15px;
}

.widget-1-item .welcome-item {
    margin: 20px;
    height: 500px;
    padding: 0;
    position: relative;
}

.widget-1-item-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 50%;
    width: 100%;
}

.widget-1-item-text {
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
}

.white-bar {
    position: absolute;
    bottom: 0;
    background-color: white;
    height: 25px;
    width: 100%;
}

.right-arrow, .left-arrow {
    font-size: 60px;
    color: white;
    position: absolute;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.3);
    top: 50%;
    padding: 10px;
    border-radius: 10%;
    transition: all 0.3s ease-in-out;
}

.left-arrow {
    left: -20px;
}

.right-arrow {
    right: -20px;
}

.right-arrow:hover, .left-arrow:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.6);
    transform: scale(1.1);
}

.block-title {
    text-transform: uppercase;
    font-weight: 900;
    /*font-style: italic;*/
    /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
    /*font-family: 'Roboto Slab', serif;*/
    font-size: 27px;
    margin-left: 57px;
}

.vertical-line {
    position: absolute;
    width: 2px;
    height: 450px;
    background-color: #777777;
    margin-top: 15px;
}

.horizontal-line {
    position: absolute;
    height: 2px;
    width: 50px;
    background-color: #777777;
    margin-top: 13px;
}

.more-items-holder {
    margin-top: 30px;
}

#more-stories-title {
    margin: 30px;
    font-size: 40px;
}

.widget-2-content {
    text-align: center;
}

.dots-holder {

}

.slick-dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    width: 95%;
    padding: 0;
    margin-left: 2.5%;
}

.slick-dots li {
    width: 100%;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.5);
    height: 7px;
    display: flex;
    flex-direction: row;
    margin-top: -595px;
    margin-left: 2px;
    margin-right: 2px;
    z-index: 6000;
}

.slick-dots button {
    width: 0;
    font-size: 0;
    background-color: transparent;
    border: none!important;
    height: 7px;
}

.slick-dots:hover {
    cursor: pointer;
}

.slick-active button, .slick-active-manual button {
    /*background-color: red;*/
    animation: load 4s linear;
    animation-fill-mode: forwards;
}

.full-bar {
    width: 100%!important;
    background-color: rgba(255, 255, 255, 0.7)!important;
}

@media (max-width: 500px) {
    .big-item-text h1 {
        font-size: 15px !important;
    }

    .big-item-text p {
        font-size: 10px !important;
    }
}

@keyframes load {
    0% {
        width: 0;
    }
    5% {
        background-color: rgba(255, 255, 255, 0.7);
    }
    100% {
        background-color: rgba(255, 255, 255, 0.7);
        width: 100%;
    }
}

.big-item, .small-item {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
}

.big-item {
    height: 600px;
}

.big-item-text, .small-item-text {
    color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
}

.big-item-text {
    min-height: 150px;
    padding: 5px;
}

.big-item-text h1 {
    font-weight: bold;
    font-size: 35px;
    text-transform: uppercase;
}

.big-item-text p {
    font-weight: lighter;
    font-size: 17px;
}

.small-item {
    height: 250px;
}

.small-item-text {
    min-height: 60px;
    text-transform: uppercase;
}

.widget-2-content .slick-slide {
    margin: 5px;
}

.widget-2-content a:hover {
    text-decoration: none;
}

.widget-3-content {
    /*color: black;*/
    /*margin-left: 10%;*/
    /*margin-right: 10%;*/
}

:root {
    --bigHeight: 700px;
}

.left-big-item {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    background-color: white;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease-in-out;
    display: block;
    text-decoration: none !important;
    color: black;
    position: relative;
}

.left-big-item-holder {
    padding: 5px;
    height: var(--bigHeight);
}

.left-big-item:hover, .right-small-item:hover {
    box-shadow: 1px 3px 5px 0px grey;
    color: black;
}

.left-big-item-img {
    width: 100%;
    min-height: 66%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.left-big-item h3 {
    font-family: 'Roboto Slab', serif;
    font-weight: bold;
    margin-top: 0;
    font-size: 50px;
}

.left-big-item-text {
    text-align: center;
    background-color: white;
    min-height: calc(100% - 66%);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 5px;
    width: 100%;
    color: black;
    position: absolute;
    bottom: 0;
}

.small-item-holder {
    height: var(--bigHeight);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0;
}

.right-small-item {
    background: white;
    height: 50%;
    margin: 5px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15);
    display: block;
    text-decoration: none !important;
    position: relative;
}

.right-small-item-img {
    height: 75%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.right-small-item-text {
    background-color: white;
    min-height: 25%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 5px;
    color: black;
    position: absolute;
    bottom: 0;
}

.right-small-item-text h3 {
    margin: 0;
    font-family: 'Roboto Slab', serif;
    font-weight: bold;
}

.right-small-item-body {
    font-size: 0;
    transition: all 0.3s ease-in-out;
}

.widget-3-content .small {
    padding: 0;
    margin: 0;
}

@media (max-width: 1200px) {
    .left-big-item-text h3 {
        font-size: 30px;
    }
}

.right-small-item:hover .right-small-item-body {
    font-size: 13px;
    animation: appearText 0.3s;
}

@keyframes appearText {
    0% {
        color: transparent;
    }
    99% {
        color:transparent;
    }
    100% {
        color: black;
    }
}

.title-holder h1 {
    margin-left: 0;
}